.order-form {
  margin-top: -1rem;
}

.product-preview {
  position: relative;
  height: 575px;
  width: 100%;
  canvas {
    height: 575px;
    width: 100%;
  }
  &.static canvas {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
  }
}

.product {
  margin-bottom: 16px;
  padding-bottom: 0px;
  > .close {
    width: 30px;
    height: 30px;
    right: 0;
    position: absolute;
  }
}

.btn-product-status {
  width: 120px;
}

// Datepicker

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  color: #fff;
  // background-color: #204d74;
  background-color: $primary;
  // border-color: #122b40;
  border-radius: 4px;
  border: none;
}

.gj-datepicker-bootstrap [role="right-icon"] button {
  width: 30px;
  position: relative;
  border: 1px solid #ced4da;
  padding: 0;
}

.form-group-invalid {
  .input-group-append {
    .input-group-text {
      border-color: $danger;
      color: $danger;
      background-color: theme-color-level("danger", $alert-bg-level);
    }
  }

  > .gj-datepicker-bootstrap [role="right-icon"] button {
    border-color: $danger;
    color: $danger;
  }
  > .invalid-feedback {
    display: block;
  }
}

// Print

@media print {
  .order-info {
    display: flex;
  }

  .page-break-after {
    margin: 0;
    border: none;
    page-break-after: always;
  }

  .product-preview {
    height: 400px;
    width: 100%;
    canvas {
      height: 400px;
      width: 100%;
    }
  }

  .table th,
  .table td {
    padding: 0.2rem;
  }
}

// Labels

// ZDesigner ZD421-203dpi

.print-label-104x149mm {
  width: 149mm;
  height: 104mm;
  zoom: 275%;
  // Rotate the label 90 degrees
  left: 104mm;
  transform-origin: top left;
  transform: rotateZ(90deg);

  position: relative;

  h1 {
    font-size: 45mm;
    line-height: 38mm;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company-name, .batch {
    font-size: 9mm;
    font-weight: bold;
    line-height: 9mm;
    padding: 1mm 0;
  }

  .qty {
    font-size: 18mm;
    font-weight: bold;
    margin-bottom: -5mm;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .barcode {
    position: absolute;
    width: 45mm;
    height: 45mm;
    bottom: 0;
    right: 0;
  }
}
