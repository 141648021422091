@import "../stylesheets/bootstrap/bootstrap-custom";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "../stylesheets/lib/dataTables.bootstrap4";

@import "~select2/dist/css/select2";
@import "~select2-theme-bootstrap4/src/select2-bootstrap.scss";
@import "~gijgo/css/gijgo";
@import "../stylesheets/lib/select2.bootstrap4";

@import "../stylesheets/datatables";
@import "../stylesheets/site";
@import "../stylesheets/orders";

body {
  margin-bottom: 60px;
}

label {
  margin-bottom: 0.1rem;
}
