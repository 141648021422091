.table {
  table-layout: fixed;
  th {
    text-align: center !important;
    font-weight: normal;
  }
  td {
    padding: $table-cell-padding;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  th {
    padding: $table-cell-padding;
    overflow: hidden;
    white-space: nowrap;
  }
}

#btn-xlsx-export-container {
  float: left;
  .btn {
    margin-top: 8px;
    margin-right: 8px;
  }
}

input.checkbox-xlsx-export {
  margin-left: 2px;
}
