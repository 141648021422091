body {
  margin-bottom: 200px;
}

abbr[title=required] {
  text-decoration: none;
  border-bottom: none;
  cursor: default;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
